import React from "react";

import ContactForm from "../../../../ContactForm";

const MA = ({ useFormalAddress, lang, name = "ma" }) => {
    return (
        <ContactForm
            urlEnding="ma"
            pushToGTMString={`${name}:contact-form`}
            canSendStorageString={`${name}::contact-form:sent-at`}
            scrollToAnchor="contact"
            useFormalAddress={useFormalAddress}
            lang={lang}
        />
    );
};

export default MA;
